<template>
  <b-row>
    <b-col sm="6" md="2" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Par page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
          @change="$emit('update:perPage', $event)"
        />
      </b-form-group>
    </b-col>
    <b-col md="6" />
    <b-col md="4" class="my-1">
      <b-form-group>
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Rechercher"
            @input="$emit('update:filter', $event)"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Effacer
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        ref="refListTable"
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        :items="items"
        :sort-by.sync="sortBy"
        :filter="filter"
        show-empty
        empty-text="Aucun enregistrements correspondants trouvés"
        :sort-desc.sync="sortDesc"
      >
      <template #cell(id)="data">
          {{ data.index + 1 }}
      </template>
      <template #cell(debit)="data">
          {{ amountToCurrency(data.item.debit, 'fr-FR', null) }}
      </template>
      <template #cell(credit)="data">
          {{ amountToCurrency(data.item.credit, 'fr-FR', null) }}
      </template>
      <template #cell(balance)="data">
          {{ amountToCurrency(data.item.balance, 'fr-FR', null) }}
      </template>
      <template #cell(amount)="data">
          {{ amountToCurrency(data.item.amount, 'fr-FR', null) }}
      </template>
      <template #cell(approved_amount)="data">
          {{ amountToCurrency(data.item.approved_amount, 'fr-FR', null) }}
      </template>
      <template #cell(total_paid)="data">
          {{ amountToCurrency(data.item.total_paid, 'fr-FR', null) }}
      </template>
        <!-- Column: Project code -->
        <template #cell(project)="data">
          <b-link
            v-if="data.item.project"
            :to="{
              name: 'projects-view',
              params: { id: data.item.project.uid },
            }"
            class="font-weight-bold"
          >
            #{{ data.item.project.code }}
          </b-link>
        </template>
        <!-- Column: Project code -->
        <template #cell(created_at)="data">
        {{ formatDate(data.item.created_at) }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <expense-handler
              v-if="data.item.status == 'pending' && data.item.project && $can('edit_request', 'Caisse Générale')"
              :index="data.index"
              :projectId="data.item.project ? data.item.project.uid : ''"
              class="d-inline"
              :btnFormat="false"
              :requestData="data.item"
              @expenseUpdated="expenseHandled"
            />
            <b-button
            v-if="$can('approve_request', 'Caisse Générale')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-success"
              class="btn-icon rounded-circle"
              @click="approveRequest(data.item)"
            >
              <feather-icon
                :id="`expense-row-${data.item.id}-approve-icon`"
                :icon="
                  data.item.status == 'pending' ? 'CheckIcon' : 'UploadIcon'
                "
                size="16"
              />
            </b-button>
            <b-tooltip
              :title="
                data.item.status == 'pending'
                  ? 'Approuver Requête'
                  : 'Effectuer Paiement'
              "
              class="cursor-pointer"
              :target="`expense-row-${data.item.id}-approve-icon`"
            />

            <b-button
              v-if="(data.item.status == 'pending' || data.item.status == 'approved') && $can('revoke_request', 'Caisse Générale')"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="rejectRequest(data.item)"
            >
              <feather-icon
                :id="`expense-row-${data.item.id}-reject-icon`"
                icon="XIcon"
                size="16"
              />
            </b-button>
            <b-tooltip
              v-if="data.item.status == 'pending' || data.item.status == 'approved'"
              title="Rejeter Requête"
              :target="`expense-row-${data.item.id}-reject-icon`"
            />
          </div>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination
        :id="`pagination-${concernedList}`"
        v-model="currentPage"
        @change="$emit('update:currentPage', $event)"
        :total-rows="total"
        :per-page="perPage"
        first-number
        last-number
        align="center"
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" /> </template
      ></b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import expensesMixin from "@/mixins/expenses"
import {
  BRow,
  BCol,
  BLink,
  BTable,
  BTooltip,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ExpenseHandler from "@/components/expenses/ExpenseHandler.vue";
import { amountToCurrency } from "@/utils/formatAmount";
import { formatDate } from "@core/utils/filter";

export default {
  props: {
    concernedList: String,
    fields: {
      type: Array,
      default: () => [],
    },
    perPage: {
      type: Number,
      required: true,
    },
    pageOptions: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDesc: {
      type: Boolean,
      required: true,
    },
    sortDirection: {
      type: String,
      required: true,
    },
    filter: {
      type: String,
      required: true,
    },
    filterOn: {
      type: Array,
      default: () => [],
    },
    items: {
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  components: {
    BRow,
    BCol,
    BLink,
    BTooltip,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,

    ExpenseHandler,
  },
  directives: {
    Ripple,
  },
  mixins: [expensesMixin],
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    amountToCurrency,
    formatDate,
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>